<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headerList"
        :items="itemList"
        hide-default-footer
      >
        <template #item.type_hebergement="{ item }"> 
          <td class="title-column cassiopee-purple--text text-h4 d-flex align-start pt-6 px-6 text-uppercase"> 
            {{ item.type_hebergement }} 
          </td>
        </template>
        <template #item.detail_type_hebergement="{ item }"> 
          <div 
            v-for="(detail,i) in item.detail_type_hebergement" 
            :key="i"
            class="small-row cassiopee-purple--text d-flex align-center pl-4 text-h4"
          >
            <td>
              <span>{{ detail }}</span>
            </td>
          </div>  
        </template>
        <template #item.calcul_outil="{ item }"> 
          <div 
            v-for="(number,i) in item.calcul_outil" 
            :key="i"
            class="small-row d-flex align-center pl-8 text-body-1"
          >
            <td>
              <span 
                class="cassiopee-purple--text"
                :class="{'table-value-disabled': valueChange(item, i) }"
              >
                {{ number }}
              </span>
            </td>
          </div>  
        </template>
        <template #item.user_modif="{ item }"> 
          <div 
            v-for="(number,i) in item.user_modif" 
            :key="i"
            class="small-row d-flex align-center pl-8 text-body-1"
          >
            <td>
              <input 
                id="user_modif_value" 
                v-model="number.value"
                type="number" 
                :placeholder="number.placeholder" 
                name="user_modif_value"
                class="table-input-custom  cassiopee-purple--text"
                @change="$emit('besoinChange', { attribute: number.attribute, value: $event.target.value })"
              >
            </td>
          </div>  
        </template>
        <template #item.vision_un_an="{ item }"> 
          <div 
            v-for="(number,i) in item.vision_un_an" 
            :key="i"
            class="small-row d-flex align-center pl-8 text-body-1"
          >
            <td>
              <input 
                id="vision_un_an_value" 
                v-model="number.value"
                type="number" 
                :placeholder="number.placeholder" 
                name="vision_un_an_value"
                class="table-input-custom cassiopee-purple--text"
                @change="$emit('besoinChange', { attribute: number.attribute, value: $event.target.value })"
              >
            </td>
          </div>  
        </template>
        <template #item.vision_trois_ans="{ item }"> 
          <div 
            v-for="(number,i) in item.vision_trois_ans" 
            :key="i"
            class="small-row d-flex align-center pl-8 text-body-1"
          >
            <td>
              <input 
                id="vision_trois_ans_value" 
                :value="number.value"
                type="number" 
                :placeholder="number.placeholder" 
                name="vision_trois_ans_value"
                class="table-input-custom cassiopee-purple--text"
                @change="$emit('besoinChange', { attribute: number.attribute, value: $event.target.value })"
              >
            </td>
          </div>  
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseTable",
  props: {
    itemList: {
      type: Array,
      required: true,
    },
    headerList: {
      type: Array,
      required: true,
    }
  },
  methods: {
    valueChange(item, index) {
    return (item.user_modif[index].value !== null && item.user_modif[index].value !== "");
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';

table { 
  background-color:  map-deep-get($colors, 'cassiopee-grey', 'lighten-1');
  border: 1px solid map-deep-get($colors, 'cassiopee-purple', 'base');
  border-bottom: none;

  thead {
    height: 103px;
  }

  tbody > tr > td { 
    padding: 0px 0px !important;
    width: 176px;
    border-bottom: 1px solid map-deep-get($colors, 'cassiopee-purple', 'base') !important;
  }
}

.title-column {
  height: 171px;
  width: 176px;
}

.small-row {
  height: 57px;
  border-bottom: 1px solid map-deep-get($colors, 'cassiopee-purple', 'base');
  border-right: 1px solid map-deep-get($colors, 'cassiopee-purple', 'lighten-2');

  &:nth-child(even){
    background-color:map-deep-get($colors, 'cassiopee-purple', 'lighten-2');
  }

  &:last-child {
    border-bottom: none;
  }
}

.table-value-disabled {
  color: map-deep-get($colors, 'cassiopee-purple', 'base');
  opacity: 0.25;
}

.table-input-custom {
  cursor: pointer;
  outline: none;

  &::placeholder {
    color: map-deep-get($colors, 'cassiopee-purple', 'lighten-6');
  }
}

</style>