<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Vue Macro des capacités d’hébergement utilisées " />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-alert 
            v-if="changeAlert"
            message="Les valeurs ont été modifiées et ne correspondent pas aux ressources préalablement renseignées."
            icon="$warning"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <base-table
            :item-list="itemList"
            :header-list="headerList"
            @change="saveFormAnswer(); changeAlert = true;" 
            @besoinChange="updateValue"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <base-subtitle 
            subtitle="Vos besoins d'hébergement sont-ils satisfaits par vos ressources d'hébergement ?" 
            class="mt-1"
            commentaire="Vos ressources d'hébergement sont-elles suffisantes au regard de vos besoins ?"
          />
          <form-fields-radio-group 
            v-model="besoin_capacite_hebergement_ok"
            :radio-data-list="booleanList"
            field-label="Capacité"
            class="mt-6 mb-6"
            hint="Les capacités de vos différents hébergements sont-elles suffisantes pour vos besoins ?"
            persistent-hint
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-if="besoin_capacite_hebergement_ok === 'Non'"
            v-model="besoin_capacite_precision"
            field-label="Vous pouvez préciser"
            placeholder="Vous pouvez préciser"
            @change="saveFormAnswer"
          />
          <form-fields-radio-group 
            v-model="besoin_fiabilite_hebergement_ok"
            :radio-data-list="booleanList"
            field-label="Fiabilité"
            hint="La fiabilité et la disponibilité des infrastructures de vos différents hébergements sont-elles suffisantes pour vos besoins ?"
            class="mb-6"
            persistent-hint
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-if="besoin_fiabilite_hebergement_ok === 'Non'"
            v-model="besoin_fiabilite_precision"
            field-label="Vous pouvez préciser"
            placeholder="Vous pouvez préciser"
            @change="saveFormAnswer"
          />
          <form-fields-radio-group 
            v-model="besoin_securite_hebergement_ok"
            :radio-data-list="booleanList"
            field-label="Sécurité"
            hint="La sécurité physique des infrastructures de vos différents hébergements est-elle suffisante pour vos besoins ?"
            class="mb-6"
            persistent-hint
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-if="besoin_securite_hebergement_ok === 'Non'"
            v-model="besoin_securite_ok_precision"
            field-label="Vous pouvez préciser"
            placeholder="Vous pouvez préciser"
            @change="saveFormAnswer"
          />
          <form-fields-radio-group 
            v-model="besoin_services_hebergement_ok"
            :radio-data-list="booleanList"
            field-label="Services"
            hint="Les services sollicités auprès de ressources d'hébergements interne ou externe, sont-ils suffisants pour vos besoins ?"
            class="mb-6"
            persistent-hint
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-if="besoin_services_hebergement_ok === 'Non'"
            v-model="besoin_services_ok_precision"
            field-label="Vous pouvez préciser"
            placeholder="Vous pouvez préciser"
            @change="saveFormAnswer"
          />
          <form-fields-radio-group 
            v-model="besoin_delai_mise_a_disposition_ok"
            :radio-data-list="booleanList"
            field-label="Délai de mise à disposition"
            hint="Le délai de mise à disposition de nouvelles capacités ou de services auprès de ressources d'hébergements interne ou externe, est-il suffisant pour vos besoins ?"
            class="mb-6"
            persistent-hint
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-if="besoin_delai_mise_a_disposition_ok === 'Non'"
            v-model="besoin_delai_mise_a_disposition_precision"
            field-label="Vous pouvez préciser"
            placeholder="Vous pouvez préciser"
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { sumBy } from 'lodash';
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsRadioGroup from '@/components/FormFieldsRadioGroup.vue'
import FormFieldsText from '@/components/FormFieldsText.vue'
import FormAlert from '@/components/FormAlert.vue'
import BaseSubtitle from '@/components/BaseSubtitle.vue'
import BaseTable from '@/components/BaseTable.vue'

export default {
  name: "MacroView",
  components: {
    FormStepTitle,
    FormFieldsRadioGroup,
    FormFieldsText,
    FormAlert,
    BaseSubtitle,
    BaseTable
  },
  data () {
    return {
      booleanList: [
        {
          label: "Oui",
          value: "Oui",
        },
        {
          label: "Non",
          value: "Non",
        },
        {
          label: 'Ne sait pas',
          value: 'Ne sait pas'
        }
      ],
      headerList: [
        {
          value: "type_hebergement",
          sortable: false,
          class: "white--text cassiopee-purple text-h4"
        },
        {  
          value: "detail_type_hebergement",
          sortable: false,
          class: "white--text cassiopee-purple text-h4"
        },
        { 
          text: "Actuellement calculé par l'outil", 
          value: "calcul_outil",
          sortable: false,
          align: 'center',
          class: "white--text cassiopee-purple text-h4"
        },
        { 
          text: "Actuellement modifié par l'utilisateur", 
          value: 'user_modif',
          sortable: false,
          align: 'center',
          class: "white--text cassiopee-purple text-h4"
        },
        { 
          text: 'Votre vision à 1 an', 
          value: 'vision_un_an',
          sortable: false,
          align: 'center',
          class: "white--text cassiopee-purple text-h4"
        },
        { 
          text: 'Votre vision à 3 ans', 
          value: 'vision_trois_ans',
          sortable: false, 
          align: 'center',
          class: "white--text cassiopee-purple text-h4"
        },
      ],
      changeAlert: false,
    }
  },
  computed: {
    ...mapFields('form', [
      'formAnswer.besoin_capacite_hebergement_ok',
      'formAnswer.besoin_fiabilite_hebergement_ok',
      'formAnswer.besoin_securite_hebergement_ok',
      'formAnswer.besoin_services_hebergement_ok',
      'formAnswer.besoin_delai_mise_a_disposition_ok',
      'formAnswer.besoin_nombre_baies',
      'formAnswer.besoin_puissance',
      'formAnswer.besoin_nombre_site',
      'formAnswer.besoin_prevision_1_an_nombre_baies',
      'formAnswer.besoin_prevision_1_an_puissance',
      'formAnswer.besoin_prevision_1_an_nombre_site',
      'formAnswer.besoin_prevision_3_ans_nombre_baies',
      'formAnswer.besoin_prevision_3_ans_puissance',
      'formAnswer.besoin_prevision_3_ans_nombre_site',
      'formAnswer.besoin_capacite_precision',
      'formAnswer.besoin_fiabilite_precision',
      'formAnswer.besoin_securite_ok_precision',
      'formAnswer.besoin_services_ok_precision',
      'formAnswer.besoin_delai_mise_a_disposition_precision',
      'formAnswer.besoin_housing_nombre_baies',
      'formAnswer.besoin_housing_puissance',
      'formAnswer.besoin_housing_nombre_contrats',
      'formAnswer.besoin_housing_prevision_1_an_nombre_baies',
      'formAnswer.besoin_housing_prevision_1_an_puissance',
      'formAnswer.besoin_housing_prevision_1_an_nombre_contrats',
      'formAnswer.besoin_housing_prevision_3_ans_nombre_baies',
      'formAnswer.besoin_housing_prevision_3_ans_puissance',
      'formAnswer.besoin_housing_prevision_3_ans_nombre_contrats',
      'formAnswer.besoin_iaas_nombre_vms',
      'formAnswer.besoin_iaas_stockage',
      'formAnswer.besoin_iaas_nombre_contrats',
      'formAnswer.besoin_iaas_prevision_1_an_nombre_vms',
      'formAnswer.besoin_iaas_prevision_1_an_stockage',
      'formAnswer.besoin_iaas_prevision_1_an_nombre_contrats',
      'formAnswer.besoin_iaas_prevision_3_ans_nombre_vms',
      'formAnswer.besoin_iaas_prevision_3_ans_stockage',
      'formAnswer.besoin_iaas_prevision_3_ans_nombre_contrats',
      'formAnswer.besoin_saas_nombre_contrats',
      'formAnswer.besoin_saas_prevision_1_an_nombre_contrats',
      'formAnswer.besoin_saas_prevision_3_ans_nombre_contrats',
    ]),
    localItEnPropre() {
      return this.localIts.filter((localIt) => localIt.type_local_it === 'Local informatique / Datacenter / Hébergement en propre')
    },
    localItHousingColocation() {
      return this.localIts.filter((localIt) => localIt.type_local_it === 'Housing / Colocation')
    },
    localItIaasPaas() {
      return this.localIts.filter((localIt) => localIt.type_local_it === 'IAAS / PAAS')
    },
    localItSaas() {
      return this.localIts.filter((localIt) => localIt.type_local_it === 'Service SAAS')
    },
    itemList() {
      return [
          {
            type_hebergement: "Hébergement en propre",
            detail_type_hebergement: [
              'Locaux IT',
              'Baies occupées',
              'Puissance (kW IT) consommée '
            ],
            calcul_outil: [
              this.localItEnPropre.length,
              this.entite.nombre_baie_calculee_hebergement_propre,
              this.entite.puissance_hebergement_propre,
            ],
            user_modif: [
              {
                placeholder: 'Locaux IT',
                value: this.besoin_nombre_site,
                attribute: 'besoin_nombre_site',
              },
              {
                placeholder: 'Nombre de Baies',
                value: this.besoin_nombre_baies,
                attribute: 'besoin_nombre_baies',
              },
              {
                placeholder: 'Puissance (kW)',
                value: this.besoin_puissance,
                attribute: 'besoin_puissance',
              }
            ],
            vision_un_an: [
              {
                placeholder: 'Locaux IT',
                value: this.besoin_prevision_1_an_nombre_site,
                attribute: 'besoin_prevision_1_an_nombre_site',
              },
              {
                placeholder: 'Nombre de Baies',
                value: this.besoin_prevision_1_an_nombre_baies,
                attribute: 'besoin_prevision_1_an_nombre_baies',
              },
              {
                placeholder: 'Puissance (kW)',
                value: this.besoin_prevision_1_an_puissance,
                attribute: 'besoin_prevision_1_an_puissance',
              }
            ],
            vision_trois_ans: [
              {
                placeholder: 'Locaux IT',
                value: this.besoin_prevision_3_ans_nombre_site,
                attribute: 'besoin_prevision_3_ans_nombre_site',
              },
              {
                placeholder: 'Nombre de Baies',
                value: this.besoin_prevision_3_ans_nombre_baies,
                attribute: 'besoin_prevision_3_ans_nombre_baies'
              },
              {
                placeholder: 'Puissance (kW)',
                value: this.besoin_prevision_3_ans_puissance,
                attribute: 'besoin_prevision_3_ans_puissance'
              }
            ],
          },
          {
            type_hebergement: "Hébergement Housing / Colocation",
            detail_type_hebergement: [
              'Contrats',
              'Baies',
              'Puissance (kW)'
            ],
            calcul_outil: [
              this.localItHousingColocation.length,
              this.entite.nombre_baie_calculee_hebergement_housing_colocation,
              this.entite.puissance_hebergement_housing_colocation,
            ],
            user_modif: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_housing_nombre_contrats,
                attribute: 'besoin_housing_nombre_contrats',
              },
              {
                placeholder: 'Baies',
                value: this.formAnswer.besoin_housing_nombre_baies,
                attribute: 'besoin_housing_nombre_baies',
              },
              {
                placeholder: 'Puissance (kW)',
                value: this.formAnswer.besoin_housing_puissance,
                attribute: 'besoin_housing_puissance',
              }
            ],
            vision_un_an: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_housing_prevision_1_an_nombre_contrats,
                attribute: 'besoin_housing_prevision_1_an_nombre_contrats',
              },
              {
                placeholder: 'Baies',
                value: this.formAnswer.besoin_housing_prevision_1_an_nombre_baies,
                attribute: 'besoin_housing_prevision_1_an_nombre_baies',
              },
              {
                placeholder: 'Puissance (kW)',
                value: this.formAnswer.besoin_housing_prevision_1_an_puissance,
                attribute: 'besoin_housing_prevision_1_an_puissance',
              }
            ],
            vision_trois_ans: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_housing_prevision_3_ans_nombre_contrats,
                attribute: 'besoin_housing_prevision_3_ans_nombre_contrats',
              },
              {
                placeholder: 'Baies',
                value: this.formAnswer.besoin_housing_prevision_3_ans_nombre_baies,
                attribute: 'besoin_housing_prevision_3_ans_nombre_baies',
              },
              {
                placeholder: 'Puissance (kW)',
                value: this.formAnswer.besoin_housing_prevision_3_ans_puissance,
                attribute: 'besoin_housing_prevision_3_ans_puissance',
              }
            ],
          },
          {
            type_hebergement: "Hébergement IAAS/PAAS",
            detail_type_hebergement: [
              'Contrats',
              'VM',
              'Stockage'
            ],
            calcul_outil: [
              this.localItIaasPaas.length,
              sumBy(this.localItIaasPaas, (localIt) => localIt.capacite_serveurs_nombre_machine_virtuelle_actuel),
              sumBy(this.localItIaasPaas, (localIt) => localIt.capacite_serveurs_capacite_stockage_disque_actuel),
            ],
            user_modif: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_iaas_nombre_contrats,
                attribute: 'besoin_iaas_nombre_contrats',
              },
              {
                placeholder: 'VM',
                value: this.formAnswer.besoin_iaas_nombre_vms,
                attribute: 'besoin_iaas_nombre_vms'
              },
              {
                placeholder: 'Stockage',
                value: this.formAnswer.besoin_iaas_stockage,
                attribute: 'besoin_iaas_stockage',
              }
            ],
            vision_un_an: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_iaas_prevision_1_an_nombre_contrats,
                attribute: 'besoin_iaas_prevision_1_an_nombre_contrats',
              },
              {
                placeholder: 'VM',
                value: this.formAnswer.besoin_iaas_prevision_1_an_nombre_vms,
                attribute: 'besoin_iaas_prevision_1_an_nombre_vms',
              },
              {
                placeholder: 'Stockage',
                value: this.formAnswer.besoin_iaas_prevision_1_an_stockage,
                attribute: 'besoin_iaas_prevision_1_an_stockage',
              }
            ],
            vision_trois_ans: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_iaas_prevision_3_ans_nombre_contrats,
                attribute: 'besoin_iaas_prevision_3_ans_nombre_contrats',
              },
              {
                placeholder: 'VM',
                value: this.formAnswer.besoin_iaas_prevision_3_ans_nombre_vms,
                attribute: 'besoin_iaas_prevision_3_ans_nombre_vms',
              },
              {
                placeholder: 'Stockage',
                value: this.formAnswer.besoin_iaas_prevision_3_ans_stockage,
                attribute: 'besoin_iaas_prevision_3_ans_stockage',
              }
            ],
          },
          {
            type_hebergement: "Hébergement SAAS",
            detail_type_hebergement: [
              'Contrats',
            ],
            calcul_outil: [
              this.localItSaas.length,
            ],
            user_modif: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_saas_nombre_contrats,
                attribute: 'besoin_saas_nombre_contrats',
              },
            ],
            vision_un_an: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_saas_prevision_1_an_nombre_contrats,
                attribute: 'besoin_saas_prevision_1_an_nombre_contrats',
              }
            ],
            vision_trois_ans: [
              {
                placeholder: 'Contrats',
                value: this.formAnswer.besoin_saas_prevision_3_ans_nombre_contrats,
                attribute: 'besoin_saas_prevision_3_ans_nombre_contrats',
              }
            ],
          },
      ]
    },
    ...mapState('form', ['formAnswer', 'localIts', 'formAnswerEnums', 'entite']),
  },
  created() {
    this.fetchFormAnswer();
    this.fetchLocalIts();
    this.setNextView('/needs/mutualized-datacenter');
    this.fetchFormAnswersEnums();
    this.fetchEntite();
  },
  methods: {
    ...mapActions('form', ['fetchFormAnswer', 'saveFormAnswer', 'fetchLocalIts', 'fetchFormAnswersEnums', 'fetchEntite']),
    ...mapMutations('stepper', ['setNextView']),
    updateValue({ attribute, value }) {
      this.formAnswer[attribute] = value;
      this.saveFormAnswer();
    },
  },
}
</script>
